import { Body } from './styles'
import { PrismicRichText } from 'Components/PrismicRichText'

const Index = (props: any) => {
  const { data } = props
  return (
    <>
      <Body>
        <PrismicRichText>{data?.primary?.body1}</PrismicRichText>
      </Body>
    </>
  )
}

export default Index
