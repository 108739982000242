import { PrismicRichText } from 'Components/PrismicRichText'
import { PageHeader } from './styles'

const Index = (props: any) => {
  const { data } = props
  return (
    <>
      <PageHeader>
        <PrismicRichText>{data?.primary?.title}</PrismicRichText>
      </PageHeader>
    </>
  )
}

export default Index
