import { useState } from 'react'
import { Wrapper, Details } from './styles'
import { PrismicRichText } from 'Components/PrismicRichText'

const AcordionPanel = (props: any) => {
  const { data } = props
  const [active, setActive] = useState(false)

  const toggle = (index) => {
    if (active === index) return setActive(false)
    setActive(index)
  }

  return (
    <>
      <Wrapper>
        <section className="lead">
          <PrismicRichText>{data?.primary?.title}</PrismicRichText>
          <PrismicRichText>{data?.primary?.lead_copy}</PrismicRichText>
        </section>

        {data.items
          ? data.items.map((item, index) => {
            return (
              <Details
                key={item.title[0].text ?? index}
                data-index={index}
                className={active === index ? 'active' : undefined}
              >
                <header onClick={() => toggle(index)}>
                  <img
                    src={item.icon.url}
                    alt={item.icon.alt}
                    loading="lazy"
                  />
                  <PrismicRichText>{item.title}</PrismicRichText>
                  <div className="controls">
                    {active === index
                      ? (
                        <i className="fa fa-chevron-up" aria-hidden="true"></i>
                      )
                      : (
                        <i
                          className="fa fa-chevron-down"
                          aria-hidden="true"
                        ></i>
                      )}
                  </div>
                </header>
                <div>
                  <div><PrismicRichText>{item.copy}</PrismicRichText></div>
                </div>
              </Details>
            )
          })
          : null}
      </Wrapper>
    </>
  )
}

export default AcordionPanel
