import { PrismicRichText } from 'Components/PrismicRichText'
import CenteredContainer from 'Components/containers/CenteredContainer'
import Button from 'Components/primitives/form/Button'

const DividerPanel = (props: any) => {
  const { data } = props
  return (
    <CenteredContainer contrast={data?.primary?.contrast}>
      <PrismicRichText>{data?.primary?.title}</PrismicRichText>
      <PrismicRichText>{data?.primary?.body1}</PrismicRichText>

      {data.primary.label?.[0]?.text.length > 0 && (
        <Button href={data.primary?.call_to_action?.[0]?.text}>
          {data.primary?.label?.[0]?.text}
        </Button>
      )}
    </CenteredContainer>
  )
}

export default DividerPanel
